@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200..800&display=swap");

body {
  background-color: #fafafa;
  position: relative;
  min-width: 375px;
  font-family: "Karla";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.77778;
  font-weight: 300;
  overflow-x: hidden;
}

div,
input,
textarea,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
a:focus {
  outline: none;
}

a:hover {
  text-decoration: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-zoom: none;
}
a {
  text-decoration: none;
  color: inherit;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -o-transition: all 0.5s;
}

button {
  outline: none;
  border: none;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h2 {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #262627;
  line-height: 1.1;
}

h2 span {
  font-weight: 600;
}

h5 {
  color: #0060a9;
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 0px;
}

p {
  color: #262627;
  font-size: 14px;
}

h1 {
  font-size: 53px;
  font-family: "Karla";
  font-weight: 400;
  line-height: 1;
  margin-bottom: 20px;
  color: #262627;
}

h3 {
  font-size: 24px;
  font-weight: 400;
}

h3 span {
  font-weight: 600;
}

h1 span {
  font-weight: 600;
}

h4 {
  font-size: 20px;
  margin-bottom: 5px;
}

.titleSec {
  margin-bottom: 40px;
}

strong {
  font-family: "Karla";
  font-weight: 900;
}

.boxShadow {
  box-shadow: 10px 20px 42px 0 rgb(55 55 55 / 23%);
}

.textCenter {
  text-align: center;
}

.relative {
  position: relative;
}

.btn {
  font-family: "Karla";
  font-size: 17px;
  position: relative;
  background: #0060a9;
  color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  line-height: 1.2rem;
  text-align: center;
  padding: 12px 20px 15px;
  border-radius: 100px;
  margin-bottom: 0.2rem;
  outline: none;
  transition: all 0.4s ease-in-out;
  margin-top: 10px;
}

.btn.whitebtn {
  background: #fff;
  color: #0060a9;
  box-shadow: inset 0px 0px 0px 2px #0060a9;
}

/* Media */

@media (max-width: 650px) {
  h1 {
    font-size: 33px;
    margin-top: 20px;
  }
  h4 {
    font-size: 17px;
  }
  h3 {
    width: 100%;
    font-size: 18px;
  }
  h2 {
    font-size: 26px;
    line-height: 1.3;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 18px;
  }
  .btn {
    font-size: 15px;
  }
}
