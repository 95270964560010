.controlSection .buttonForm {
  margin-top: 20px;
}

.controlSection img {
  width: 40%;
  min-width: 270px;
  max-height: 600px;
}
.controlSection {
  width: 100%;
  max-width: 1000px;
  text-align: left;
  gap: 40px;
  padding: 80px 20px 30px;
}
/* Media */

@media (max-width: 850px) {
  .controlSection img {
    width: 100%;
    min-width: 250px;
    height: 310px;
    object-fit: contain;
    margin-top: -140px;
    object-position: right;
    pointer-events: none;
  }
  .controlSection {
    flex-direction: column;
    margin: 50px 0 0;
    gap: 0;
    padding: 0 20px;
  }
}
