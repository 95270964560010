.swiperSec {
  width: 100%;
  max-width: 1200px;
  padding: 50px 40px 0;
  background: #efefef;
  border-radius: 30px;
}

.imgBox {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px;
  position: relative;
}

.imgBox::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1;
  border-radius: 15px;
}

.imgBox .buttonForm {
  margin-bottom: 0;
}

.imgBox img {
  margin-bottom: -120px;
  width: 60%;
  margin-top: -20px;
}

.imgBox a.boton {
  font-size: 14px;
  padding: 10px 20px;
}
.imgBox h4 {
  color: #005daa;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center !important;
  font-size: 18px;
}
.swiper {
  width: 100%;
  height: 100%;
  padding: 70px 0;
}

.swiper-slide {
  text-align: center;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  height: 100%;
  object-fit: cover;
  transition: all 0.3s;
  scale: 1;
  position: relative;
  z-index: -1;
  align-self: center;
}
.swiper-slide-next img {
  scale: 1.1;
}
.swiper-slide p {
  margin-top: 0;
  overflow: hidden;
  min-height: 0;
  transition: all 0.3s;
  max-height: 0;
  font-size: 14px;
}
.swiper-slide-next p {
  max-height: 200px;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  font-size: 20px;
}

.swiperSec .swiper-button-prev,
.swiperSec .swiper-rtl .swiper-button-next {
  left: 0px;
}

.swiperSec .swiper-button-next,
.swiperSec .swiper-rtl .swiper-button-prev {
  right: 0px;
}

@media (max-width: 991px) {
  .imgBox img {
    margin-bottom: -100px;
  }
}

@media (max-width: 768px) {
  .swiperSec {
    width: calc(100% - 30px);
  }
}

@media (max-width: 640px) {
  .swiper {
    padding-top: 0;
  }
  .swiper-slide-next p {
    max-height: 0;
  }
  .swiper-slide-active p {
    max-height: 100px;
  }
  .swiper-slide {
    padding-top: 30px;
  }
}
