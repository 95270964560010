.inputDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 15px;
  justify-content: center;
}
.inputDiv :is(input, select) {
  display: flex;
  padding: 15px 30px;
  font-size: 20px;
  color: #262627;
  background-color: #fff;
  border: none;
  box-shadow: 0px 0px 0px 1px #51493999;
  background-color: #5149391a;
  font-weight: 500;
  border-radius: 5px;
  transition: all 0.5s;
  -webkit-appearance: none;
  appearance: none;
}
.dropdown {
  position: absolute;
  right: 50px;
  width: 5px;
  transform: rotate(-90deg);
}
.inputDiv :is(input, select):focus {
  box-shadow: 0px 0px 0px 3px #0060a9;
  border: none;
  outline: none;
}
.radioDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
}

.inputDiv input[type="radio"] {
  display: none;
  margin: 10px;
}

.inputDiv input[type="radio"] + label {
  margin-right: 15px;
  background-color: #e7e7e7;
  border-color: #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px #51493999;
  padding: 10px 20px 10px 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

input[type="radio"]:checked + label {
  background-image: none;
  background-color: #0060a94d;
  box-shadow: 0px 0px 0px 2px #51493999;
  animation: checkIn 1s both;
}

.radioDiv label span {
  text-transform: uppercase;
  padding: 5px 10px;
  color: rgb(0 0 0 / 67%);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(0 0 0 / 15%);
  background-color: rgb(0 0 0 / 15%);
  margin-right: 10px;
}

@keyframes checkIn {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.labelForm {
  font-size: 14px;
  margin-bottom: 10px !important;
}

.error,
.inputDiv .errorInput {
  margin: 5px 0 0;
  color: #0060a9;
  font-size: 14px;
  display: none;
}

.error {
  margin: 15px 0 0;
  color: #0060a9;
  font-size: 14px;
  display: flex;
  align-self: center;
}
.error svg {
  width: 18px;
  margin-right: 6px;
}

.inputDiv :is(input, select):invalid[focused="true"] ~ span {
  display: flex;
}

.radioCard img {
  width: 100%;
  min-height: 150px;
  object-fit: cover;
  height: 150px;
}

.radioCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 0px !important;
  margin: 0 !important;
}

.radioDivCard {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}

.radioDivCard > div {
  flex: 1 200px;
  max-width: 214px;
}

.radioCard input[type="radio"] + label {
  width: 100%;
  background: none;
  color: #262627;
  text-align: center;
  margin: 0;
  padding: 10px 0;
  border-radius: 0;
}

@media (max-width: 650px) {
  .inputDiv :is(input, select) {
    font-size: 17px;
  }

  input[type="radio"] + label {
    margin-bottom: 0;
  }
}
