.containerBox {
  min-height: 500px;
  z-index: 999;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  padding: 0 60px;
  background-size: cover;
  text-align: center;
  align-items: center;
  background-position: center;
  padding: 100px 0 130px;
}

.infoBox {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.home h1 {
  font-weight: 700;
  font-size: 70px;
  margin-bottom: 10px;
}

.home p {
  font-size: 17px;
}

span.stepNumber {
  background: #ff822e;
  height: 31px;
  width: 30px;
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #fff;
  margin-bottom: 10px;
}

.stepHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.stepsHome h3 {
  width: 100%;
  margin-bottom: 0;
  flex: 1;
}

.stepHome p {
  margin-top: 0;
  margin-bottom: 0;
}

.stepsHome {
  display: flex;
  flex-direction: column;
  background: #ff822e1c;
  -webkit-backdrop-filter: blur(41px);
  backdrop-filter: blur(41px);
  border-radius: 30px;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 680px;
  margin-top: -90px;
}

.stepHomeBox {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.home .marquee img {
  max-height: 52px;
}

.home .marquee {
  margin-top: 0px;
  max-width: 500px;
  margin-bottom: 20px;
  mask-image: linear-gradient(
    90deg,
    transparent,
    #000 5%,
    #000 95%,
    transparent 100%
  );
}

.home .buttonForm {
  margin-bottom: 50px;
}

.landingContent {
  max-width: 960px;
  width: 100%;
}

@media (max-width: 768px) {
  .home h1 {
    font-size: 40px;
  }
  .containerBox {
    padding: 80px 10px 100px;
  }
  .stepHome {
    flex: 1;
  }
  .stepHome p {
    font-size: 14px;
  }
  .stepsHome {
    width: calc(100% - 30px);
  }
}
