.seguridadSection {
  width: 100%;
  max-width: 1000px;
  padding: 140px 20px 30px;
}

.seguridadItem {
  flex: 1;
  padding: 50px;
  background: #0060a93b;
  border-radius: 30px;
}

.seguridadDiv {
  gap: 40px;
  margin-top: 50px;
}

.seguridadSection p {
  margin: 0;
}

.seguridadDiv img {
  width: 60px;
  margin-bottom: 20px;
}

/* Media */

@media (max-width: 850px) {
  .seguridadSection {
    flex-direction: column;
    gap: 0;
    padding: 80px 20px;
  }

  .seguridadDiv {
    flex-wrap: wrap;
    gap: 20px;
  }
  .seguridadItem {
    flex: 1 300px;
    padding: 20px 30px 30px;
  }
  .seguridadItem p {
    font-size: 14px;
  }
  .seguridadDiv {
    margin-top: 30px;
  }
}
